import { mapGetters } from 'vuex';
import pendoMixin from '@/mixins/pendoMixin';
import launchDarklyService from '@/services/launchDarkly/base';

import {
  VApp,
} from 'vuetify/components';

import ErrorBoundary from 'Components/errorBoundary/errorBoundary';
import ErrorPage from 'Components/errorPage/errorPage';
import NavigationComponent from 'Components/navigationComponent/NavigationComponent.vue';
import NotificationBar from 'Components/notifications/notificationBar/NotificationBar.vue';

export default {
  components: {
    ErrorBoundary,
    ErrorPage,
    NotificationBar,
    NavigationComponent,
    VApp,
  },

  mixins: [pendoMixin],

  computed: {
    ...mapGetters('auth', ['isProfessional']),
    ...mapGetters('enrollments', ['activeOrganisation']),
    navigationBar() { return this.$route.meta.navigationBar; },
    ldContext() {
      return this.activeOrganisation?.uuid
        ? {
          kind: 'organization',
          key: this.activeOrganisation?.uuid,
          name: this.activeOrganisation?.name,
        } : {};
    },
  },

  watch: {
    async ldContext() {
      await this.initializeLaunchDarkly(this.ldContext);
    },
  },

  created() {
    this.initializePendo();
    this.initializeLaunchDarkly(this.ldContext);
  },

  methods: {
    async initializeLaunchDarkly(context) {
      if (!this.isProfessional) return;
      await launchDarklyService.setupClient(context);
    },
  },
};
