import * as LDClient from 'launchdarkly-js-client-sdk';
import launchDarklyFlags from '@/constants/launchDarklyFlags';
import { ref } from 'vue';

/**
 * Service class to handle LaunchDarkly feature flag functionality
 */
class LaunchDarklyService {
  /**
   * Creates an instance of LaunchDarklyService
   */
  constructor() {
    this.client = null;
    this.listeners = new Set();
    this.flagValues = ref({});
    this.isInitialized = false;
  }

  /**
   * Checks if the LaunchDarkly client can be setup
   * @param {Object} context - The user context for LaunchDarkly
   * @returns {Boolean} - Whether the client can be setup
   */
  static canSetupClient(context = {}) {
    return Boolean(
      import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID
      && !!Object.keys(context).length,
    );
  }

  /**
   * Sets up the LaunchDarkly client with the given context
   * @param {Object} context - The user context for LaunchDarkly
   * @returns {Promise<Object|null>} - The LaunchDarkly client instance or null
   */
  async setupClient(context) {
    if (!LaunchDarklyService.canSetupClient(context)) {
      console.log('LaunchDarkly initialization skipped - conditions not met');
      return null;
    }

    if (this.client && this.isInitialized) await this.identify(context);
    else await this.initialize(context);
    return this.client;
  }

  /**
   * Initializes the LaunchDarkly client and sets up feature flags
   * @param {Object} context - The user context for LaunchDarkly
   * @returns {Promise<Object>} - The initialized LaunchDarkly client
   * @throws {Error} If initialization fails
   */
  async initialize(context) {
    try {
      this.client = LDClient.initialize(
        import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
        context,
      );

      await this.client.waitForInitialization(5);

      // Initialize all flags
      Object.values(launchDarklyFlags).forEach((flagKey) => {
        this.flagValues.value[flagKey] = this.client.variation(flagKey, false);
        this.setupFlagListener(flagKey);
      });

      this.isInitialized = true;
      return this.client;
    } catch (error) {
      console.error('Failed to initialize LaunchDarkly:', error);
      this.client = null;
      throw error;
    }
  }

  /**
   * Sets up a listener for changes to a specific feature flag
   * @param {string} flagKey - The key of the feature flag to listen for
   */
  setupFlagListener(flagKey) {
    if (!this.client || !flagKey) return;

    const listener = (newValue) => {
      this.flagValues.value[flagKey] = newValue;
    };

    this.client.on(`change:${flagKey}`, listener);
    this.listeners.add({ flagKey, listener });
  }

  /**
   * Gets the current value of a feature flag
   * @param {string} flagKey - The key of the feature flag
   * @param {boolean} defaultValue - The default value if the flag is not found
   * @returns {*} - The value of the feature flag
   */
  getFlagValue(flagKey, defaultValue = false) {
    return this.flagValues.value[flagKey] ?? defaultValue;
  }

  /**
   * Updates the LaunchDarkly client with new user context
   * @param {Object} context - The new user context
   * @returns {Promise<Object>} - The LaunchDarkly client instance
   */
  async identify(context) {
    await this.client.identify(context);
    return this.client;
  }

  /**
   * Cleans up the LaunchDarkly client instance and removes all listeners
   * @returns {Promise<void>}
   */
  async cleanup() {
    if (!this.client) return;

    this.listeners.forEach(({ flagKey, listener }) => {
      this.client.off(`change:${flagKey}`, listener);
    });

    this.listeners.clear();
    await this.client.close();
    this.flagValues = ref({});
    this.client = null;
    this.isInitialized = false;
  }
}

export default new LaunchDarklyService();
